<template>
	<div>
		<div class="container flex-start">
			<div class="search">
				<div>
					<div class="search_item">
						<div>需求类型：</div>
						<el-select v-model="reqType" placeholder="请选择">
							<el-option v-for="item in reqTypeList" :key="item.key" :label="item.value"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_warp">
					<div class="btn" @click="queryBtn">查询</div>
					<div class="rest_btn" @click="addReqs">新增</div>
				</div>
			</div>
			<div v-loading="loading" class="hundred">
				<div v-if="tableData.length > 0" class="req-warp">
					<div class="req-warp">
						<div v-for="(item,index) in tableData" :key="item.sdr_reqId" class="req-item"
							@click="goToDetail(item)">
							<reqInfo :baseUrlpath="baseUrlpath" :item="item"></reqInfo>
						</div>
					</div>
					<div class="pagination_warp">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage1"
							:page-size="pageSize" layout="total, prev, pager, next" :total="total">
						</el-pagination>
					</div>
				</div>

				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import reqInfo from '@/components/reqInfo.vue'
	export default {
		components: {
			reqInfo
		},
		data() {
			return {
				currentPage1: 1,
				tableData: [],
				total: 0,
				pageSize: 12,
				baseUrlpath: '',
				reqType: 'all',
				reqTypeList: [],
				loading: false
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			this.reqTypeList = JSON.parse(JSON.stringify(this.$util.reqList));
			this.reqTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.query();
		},
		methods: {
			queryBtn() {
				this.currentPage1 = 1;
				this.query();
			},
			handleCurrentChange(e) {
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			query() {
				this.loading = true;
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				params.reqType = this.reqType;
				queryUrl("/apis/getMyReqs", params).then(res => {
					this.loading = false;
					vm.tableData = res.reqList;
					vm.total = res.totalNum;
				});
			},
			goToDetail(itemInfo) {
				this.$router.push({
					name: 'reqDetail',
					query: {
						reqNo: itemInfo.sdr_reqId
					}
				});
			},
			addReqs() {
				this.$router.push({
					name: 'publishIndex',
					query: {}
				});
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.req-warp {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	.container {
		width: 90%;
		margin: 0 auto;
		align-items: flex-start;
		flex-direction: column;
		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
			width: 100%;
		}
		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
		}

		.search {
			margin-top: 20px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;

			.search_item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.btn_warp {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;

				.btn {
					background-color: #C88854;
					color: #FFFFFF;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}

				.rest_btn {
					border: 1px solid #C88854;
					color: #C88854;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.req-item {
		width: calc((100% - 48px) / 4);
		border-radius: 8px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		overflow: auto;
		margin-right: 12px;
		margin-bottom: 12px;
	}
</style>
