import { render, staticRenderFns } from "./tips.vue?vue&type=template&id=173f309f&scoped=true&"
import script from "./tips.vue?vue&type=script&lang=js&"
export * from "./tips.vue?vue&type=script&lang=js&"
import style0 from "./tips.vue?vue&type=style&index=0&id=173f309f&prod&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "173f309f",
  null
  
)

export default component.exports