<template>
	<div class="container" v-loading="loading">
		<div>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="基本信息" name="first"></el-tab-pane>
				<el-tab-pane label="会员信息" name="second"></el-tab-pane>
				<el-tab-pane label="实名认证" name="third"></el-tab-pane>
				<el-tab-pane label="我的钱包" name="fourth"></el-tab-pane>
				<el-tab-pane label="我的金豆" name="fifth"></el-tab-pane>
				<el-tab-pane label="信用担保" name="sixth"></el-tab-pane>
				<el-tab-pane label="联系方式" name="seven"></el-tab-pane>
			</el-tabs>
		</div>
		<div class="jbxx" v-if="activeName == 'first'">
			<div class="warp flex-start" v-if="!firstModify">
				<div class="avatar">
					<img :src="showAvatarUrl(myInfo.sdu_avatarUrl)" @error="imgOnError"></img>
				</div>
				<div class="flexone padd">
					<el-descriptions class="margin-top" title="基本信息">
						<template slot="extra">
							<el-button type="primary" size="small" @click="modyfyFirst()">修改</el-button>
						</template>
						<el-descriptions-item label="昵称">{{myInfo.sdu_nickName}}</el-descriptions-item>
						<el-descriptions-item label="性别">{{$util.getDisPlay(myInfo.sdu_sex,$util.sexList)}}
						</el-descriptions-item>
						<el-descriptions-item label="生日">{{myInfo.sdu_brithDate}}</el-descriptions-item>
						<el-descriptions-item label="地区">{{$util.getAreaNameByAc(JSON.parse(myInfo.sdu_areaId))[1]}}
						</el-descriptions-item>
						<el-descriptions-item label="婚姻状况">
							{{$util.getDisPlay(myInfo.sdu_isMarriage,$util.marriageList)}}
						</el-descriptions-item>
						<el-descriptions-item label="职业">{{myInfo.sdu_work}}</el-descriptions-item>
					</el-descriptions>
				</div>
			</div>
			<div class="formwarp" v-else>
				<el-form ref="form" :model="form" label-width="120px">
					<div class="flex-start">
						<el-form-item label="头像">
							<el-upload
							  class="avatar-uploader"
							  action="123"
							  :http-request="uploadFileAvatar"
							  :show-file-list="false"
							  >
							  <img v-if="form.avatarUrl" :src="form.avatarUrl" class="avatar">
							  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
					</div>
					<div class="flex-start">
						<el-form-item label="昵称">
							<el-input v-model="form.nickName"></el-input>
						</el-form-item>
						<el-form-item label="性别">
							<el-select v-model="form.sexId" placeholder="请选择性别">
								<el-option label="男" value="0"></el-option>
								<el-option label="女" value="1"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="职业">
							<el-input v-model="form.workName"></el-input>
						</el-form-item>
					</div>
					<div class="flex-start">
						<el-form-item label="生日">
							<el-date-picker
								v-model="form.brithDate"
								type="date"
								format="yyyy-MM-dd"
								value-format="yyyy-MM-dd"
								placeholder="选择日期时间">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="地区">
							<el-cascader
							    v-model="form.areaId"
								:props="cascaderprops"
							    :options="citysList"
							    @change="handleCityChange">
							</el-cascader>
						</el-form-item>
						<el-form-item label="婚姻状况">
							<el-select v-model="form.marriageId" placeholder="请选择">
								<el-option
									v-for="item in $util.marriageList"
									:key="item.key"
									:label="item.value"
									:value="item.key">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div class="flex-center" style="margin-top: 30px;">
						<el-form-item>
							<el-button type="primary" @click="onSubmitFirst">修改</el-button>
							<el-button @click="cancelFirst()">取消</el-button>
						</el-form-item>
					</div>

				</el-form>
			</div>
		</div>
		<div class="hyxx" v-if="activeName == 'second'">
			<div class="warp">
				<div class="flex-start">
					<div class="vip_icon"></div>
					<div class="vip_info">
						<div class="vip_userName">{{myInfo.sdu_nickName}}</div>
						<div class="vip_desc">
							<div v-if="myInfo.sdu_ismember == '0'">您还不是会员，开通会员享受更多权益</div>
							<div v-else>您已是会员，有效期至{{myInfo.sdu_vipEndTime}}</div>
						</div>
					</div>
				</div>
			</div>
			<h3>会员充值</h3>
			<div>
				<div class="cz_warp">
					<div v-for="(item,index) in vipPriceList" :class="{'active': czType == item.sdvp_vipType}" :key="item.sdvp_vipType" class="cz_item" @click="changeCzType(item)">
						<div class="txt">{{item.sdvp_typeDesc}}</div>
						<div class="amt">
							<label class="margr4">￥</label>
							<label>{{item.sdvp_vipPrice}}</label>
						</div>
						<div class="oamt">
							<label>原价</label>
							<label>{{item.sdvp_vipOldPrice}}</label>
						</div>
					</div>
				</div>
			</div>
			
		</div>
		<div class="ssrz" v-if="activeName == 'third'">
			<div class="flex-start warp">
				<div class="input_warp">
					<el-input v-model="smForm.userRealName" placeholder="请输入您的真实姓名"></el-input>
				</div>
				<div class="input_warp">
					<el-input v-model="smForm.userHzNum" placeholder="请输入您的真实护照号"></el-input>
				</div>
			</div>
			<h3>上传护照照片</h3>
			<div class="flex-start">
				<div class="hzsl_icon margr10"></div>
				<div>
					<el-upload
					  class="avatar-uploader"
					  action="123"
					  :http-request="uploadFilePassPort"
					  :show-file-list="false"
					  >
					  <img v-if="smForm.passPortList.length > 0" :src="smForm.passPortList[0].path" class="avatar">
					  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>
			</div>
			<h3>上传日常工作照</h3>
			<div class="flex-start">
				<div v-for="(item,index) in smForm.gzImgList" :key="item.fileId" style="margin-right: 20px;">
					<img :src="showAvatarUrl(item.path)" class="avatar"></img>
				</div>
				<el-upload
				  class="avatar-uploader"
				  action="123"
				  :http-request="uploadFileWorkPic"
				  :show-file-list="false"
				  :multiple="true"
				  :limit="3"
				  :on-remove="handleWorkPicRemove"
				  :on-exceed="handleExceed"
				  :before-upload="handleBeforeUpload"
				  >
				  <i class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div class="btn_warp">
				<div class="btn" @click="saveCertif">确定</div>
			</div>
		</div>
		<div class="wallet" v-if="activeName == 'fourth'">
			<div v-if="walletForm.bindShowFlag">
				<el-form ref="walletForm" :model="walletForm" label-width="120px">
					<el-form-item label="支付宝姓名">
						<el-input v-model="walletForm.suar_Name" placeholder="请输入您的真实姓名"></el-input>
					</el-form-item>
					<el-form-item label="身份证号码">
						<el-input v-model="walletForm.suar_icNum" placeholder="请输入您的身份证号码"></el-input>
					</el-form-item>
					<el-form-item label="手机号">
						<el-input v-model="walletForm.suar_phone" placeholder="请输入您的手机号"></el-input>
					</el-form-item>
					<el-form-item label="支付宝账号">
						<el-input v-model="walletForm.suar_account" placeholder="请输入您的支付宝账号"></el-input>
					</el-form-item>
					<div class="flex-center" style="margin-top: 30px;">
						<el-form-item>
							<el-button type="primary" @click="saveBind">修改</el-button>
							<el-button @click="cancelBind()">取消</el-button>
						</el-form-item>
					</div>
				</el-form>
				<div class="condition">
					<div class="head">认证说明:</div>
					<div>1.收款人信息需要与身份证信息一致否则无法打款成功</div>
					<div>2.绑定信息仅用于提现打款，平台会严格保密</div>
					<div>3.身份证号仅用于平台内部审核，帮用户代扣代缴手续费</div>
					<div>4.如提现失败，请核实支付宝与身份证是否一致，有疑问请联系在线客服</div>
				</div>
			</div>
			<div v-else>
				<div class="warp">
					<div class="flex-start wallet_head">
						<div class="wallet_head_item">
							<div class="tit">余额</div>
							<div class="amt">
								<label>{{walletForm.remaining}}</label>
								<label class="unit">元</label>
							</div>
						</div>
						<div class="line"></div>
						<div class="wallet_head_item">
							<div class="tit">提现中</div>
							<div class="amt">
								<label>{{walletForm.withdrawApply}}</label>
								<label class="unit">元</label>
							</div>
						</div>
					</div>
				</div>
				<h3>选择提现账户</h3>
				<div class="flex-start">
					<div class="paytype" :class="{'active' : walletForm.txType == '0'}" @click="changeTxType('0')">
						<div class="wx_icon"></div>
						<div>微信</div>
					</div>
					<div class="paytype" :class="{'active' : walletForm.txType == '1'}" @click="changeTxType('1')">
						<div class="alipay_icon"></div>
						<div>支付宝</div>
					</div>
					<div class="flex-start bing_btn_warp">
						<div class="input">{{walletForm.suar_account}}</div>
						<div class="btn" @click="changeBindShow()">去绑定</div>
					</div>
				</div>
				<h3>选择提现金额</h3>
				<div class="cz_warp">
					<div v-for="(item,index) in txItemsList" :key="item.cit_key" class="cz_item" :class="{'active': walletForm.txAmtIndex == item.cit_key}" @click="changeTxAmtIndex(item.cit_key)">
						<div class="amt">
							<label>{{item.cit_value}}</label>
							<label class="unit">元</label>
						</div>
					</div>
				</div>
				<div class="btn_warp">
					<div class="btn" @click="txApply">提现申请</div>
				</div>
				<div class="tx-notice">
					<div>1.每天可提交一次提现申请，1-3个工作日到账（节假日顺延）；</div>
					<div>2.提现绑定的收款账户必须与本人实名认证一致；</div>
					<div>3.提现成功后若长时间未成功到账，请联系客服；</div>
				</div>
			</div>
			
		</div>
		<div class="cost" v-if="activeName == 'fifth'">
			<div class="warp">
				<div class="flex-start wallet_head">
					<div class="wallet_head_item">
						<div class="tit">余额</div>
						<div class="amt">
							<label>{{membercoins}}</label>
							<label class="unit">金豆</label>
						</div>
					</div>
				</div>
			</div>
			<h3>金豆充值</h3>
			<div class="cz_warp">
				<div v-for="(item,index) in JdPriceList" :key="item.cit_key" class="cz_item" :class="{'active': jdIndex == item.sdvp_czType}" @click="changejdIndex(item.sdvp_czType)">
					<div>
						<label>{{item.sdvp_buyCost}}</label>
						<label>金豆</label>
					</div>
					<div class="amt">
						<label>{{item.sdvp_needPrice}}</label>
						<label class="unit">元</label>
					</div>
				</div>
			</div>
		</div>
		<div class="guarantee" v-if="activeName == 'sixth'">
			<div class="warp flex-start">
				<div class="flex-column-center">
					<div class="tit">缴纳保证金 享专属特权</div>
					<div class="amt">
						<label>{{guaranteeAmt}}</label>
						<label class="unit">元</label>
					</div>
				</div>
				<div class="notice_msg">
					<ul>
						<li>保证金醒目标识</li>
						<li>平台活动、平台福利、优先领取和发放</li>
						<li>平台最新功能首批体验，参与平台功能设计讨论</li>
					</ul>
				</div>
			</div>
			<div class="normal_notice_msg">
				<div class="msg_item">保证金三个月后自动释放到零钱，可随时全额提现，三个月内退款扣15%服务费，三个月后0服务费并且自动退款到钱包。存入保证金后即同意以下内容：</div>
				<div class="msg_item">1.交流过程中，不会有恶意欺骗行为</div>
				<div class="msg_item">2.达成交易后，不会随意毁约</div>
				<div class="msg_item">3.未经对方同意下，不会公开对方隐私</div>
				<div class="msg_item">如果违反了其中一条，经客服同双方调查确认后，可以扣除保证金</div>
			</div>
		</div>
		<div class="mycontact" v-if="activeName == 'seven'">
			<div class="warp">
				<div class="contact">
					<div class="flex-between">
						<h3>手机号</h3>
						<div>
							<el-switch
							  v-model="phoneShow"
							  active-color="#13ce66"
							  inactive-color="#ff4949">
							</el-switch>
						</div>
					</div>
					<div class="margt14" v-if="phoneShow">
						<div class="flex-start">
							<div class="flex-start">
								<div class="input_tit">区号：</div>
								<div class="input_min_warp">
									<el-input v-model="phoneNumAc" placeholder="手机区号"></el-input>
								</div>
							</div>
							<div class="flex-start">
								<div class="input_tit">手机号：</div>
								<div class="input_warp">
									<el-input v-model="phoneNum" placeholder="手机号"></el-input>
								</div>
							</div>
						</div>
					</div>
					<div class="flex-between margt14">
						<h3>微信号</h3>
						<div>
							<el-switch
							  v-model="wxShow"
							  active-color="#13ce66"
							  inactive-color="#ff4949">
							</el-switch>
						</div>
					</div>
					<div class="margt14" v-if="wxShow">
						<div class="input_warp">
							<el-input v-model="wxCode" placeholder="微信号"></el-input>
						</div>
						<div class="margt14" style="text-align: left;">
							<el-upload
							  action="123"
							  :http-request="uploadFile"
							  list-type="picture-card"
							  :multiple="false"
							  :on-remove="handleRemove"
							  :limit="1"
							  :file-list="fileList">
							  <i class="el-icon-plus"></i>
							</el-upload>
						</div>
					</div>
					<div class="flex-between margt14">
						<h3>WhatsApp</h3>
						<div>
							<el-switch
							  v-model="whatsAppShow"
							  active-color="#13ce66"
							  inactive-color="#ff4949">
							</el-switch>
						</div>
					</div>
					<div class="margt14" v-if="whatsAppShow">
						<div class="flex-start">
							<div class="flex-start">
								<div class="input_tit">区号：</div>
								<div class="input_min_warp">
									<el-input v-model="whatsAppAc" placeholder="WhatsApp区号"></el-input>
								</div>
							</div>
							<div class="flex-start">
								<div class="input_tit">手机号：</div>
								<div class="input_warp">
									<el-input v-model="whatsAppNum" placeholder="WhatsApp号"></el-input>
								</div>
							</div>
						</div>
					</div>
					<div class="flex-between margt14">
						<h3>邮件(E-MAIL)</h3>
						<div>
							<el-switch
							  v-model="emailShow"
							  active-color="#13ce66"
							  inactive-color="#ff4949">
							</el-switch>
						</div>
					</div>
					<div class="margt14" v-if="emailShow">
						<div class="input_warp">
							<el-input v-model="emailNum" placeholder="请输入您的邮件(E-MAIL)"></el-input>
						</div>
					</div>
					<div class="btn_warp" @click="modifyContact()">
						<div class="btn">修改</div>
					</div>
				</div>
				
			</div>
		</div>
		<div class="btn_warp" v-if="activeName == 'second' || activeName == 'fifth' || activeName == 'sixth'">
			<div class="flex-center" >
				<el-popover placement="right" trigger="manual" v-model="wxPayQRcode">
					<div class="wxqrcode_warp" >		
						<div class="icon_colse" @click="colseWxQrcode()"></div>
						<div class="wxqrcode_warp_left" v-loading="wxQrcoswloading">
							<div id="wxqrcode" :style="{width:'200px',height:'200px'}"></div>
							<div class="wxqrcode_bottom">
								<div class="sys_icon"></div>
								<div class="sys-desc">
									<div>请使用微信扫一扫</div>
									<div>扫描二维码支付</div>
								</div>
							</div>
						</div>
						<div class="wxqrcode_warp_right"></div>
					</div>	
					<div class="rest_btn" slot="reference" @click="showWXQRcode()">
						<div class="icon_weixin"></div>
						<div>微信支付</div>
					</div>
				</el-popover>
				<el-popover placement="right" width="400" trigger="manual" v-model="zfbPayQRcode">
					<div class="wxqrcode_warp" >		
						<div class="icon_colse" @click="colseAlipayQrcode()"></div>
						<div class="wxqrcode_warp_left" v-loading="alipayQrcoswloading">
							<div id="alipayqrcode" :style="{width:'200px',height:'200px'}"></div>
							<div class="wxqrcode_bottom">
								<div class="sys_icon"></div>
								<div class="sys-desc">
									<div>请使用支付宝扫一扫</div>
									<div>扫描二维码支付</div>
								</div>
							</div>
						</div>
						<div class="zfbqrcode_warp_right"></div>
					</div>	
					<div class="rest_btn" slot="reference" @click="showZFBQRcode()">
						<div class="icon_zfb"></div>
						<div>支付宝支付</div>
					</div>
				</el-popover>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,commitFileUrl,postWithUInfo,queryUrlWithUInfo
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	import QRCode from 'qrcodejs2'
	import {
		mapGetters,
		mapActions
	} from 'vuex'
	export default {
		data() {
			return {
				activeName: 'first',
				loading: false,
				defalutImg: require('@/assets/icon/noimg.jpg'),
				baseUrlpath: '',
				myInfo: {},
				wxPayQRcode:false,
				zfbPayQRcode:false,
				wxQrcoswloading:true,
				alipayQrcoswloading:true,
				qrcodeWx:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
				form: {
					brithDate:'',
					areaId:[0,0],
					marriageId:'',
					sexId:'',
					nickName:'',
					workName:'',
					avatarUrl:''
				},
				smForm: {
					userRealName:'',
					userHzNum:'',
					hzImgUrl:'',
					gzImgList:[],
					passPortList:[]
				},
				walletForm:{
					remaining:0,
					withdrawApply:0,
					suar_Name:'',
					suar_account:'',
					suar_icNum:'',
					suar_phone:'',
					txType:'1',
					txAmtIndex:'2',
					bindShowFlag:false
				},
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				citysList:[],
				firstModify:false,
				vipPriceList:[],
				czType:'1',
				txItemsList:[],
				wxqrcode: null,
				alipayqrcode:null,
				checkInterval:null,
				membercoins:'0',
				JdPriceList:[],
				jdIndex:'1',
				guaranteeAmt:'',
				phoneShow:false,
				wxShow:false,
				whatsAppShow:false,
				emailShow:false,
				emailNum:'',
				qrcodeImage:'',
				phoneNum:'',
				phoneNumAc:'',
				whatsAppAc:'',
				whatsAppNum:'',
				wxCode:'',
				fileList:[]
			}
		},
		created() {
			this.activeType = this.$route.query.activeType;
			if(this.activeType == 'contact'){
				this.activeName = 'seven'
			}
			this.citysList = this.$util.citysList;
			this.baseUrlpath = this.baseUrl;
			this.queryPersonInfo();
			this.getVipPriceList();
			this.myCertificationInfo();
			this.queryMyWalletInfo();
			this.getItemsList();
			this.getAlipayAccount();
			this.remainCostQuery();
			this.queryJdPrice();
			this.getGuarantee();
			this.queryUserContact();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		watch:{
			wxPayQRcode:function(newVal,oldVal){
				if(newVal == false){
					clearInterval(this.checkInterval)
				}
			},
			zfbPayQRcode:function(newVal,oldVal){
				if(newVal == false){
					clearInterval(this.checkInterval)
				}
			}
		},
		methods: {
			async modifyContact(){
				if(!this.phoneShow && !this.wxShow && !this.whatsAppShow){
					this.$util.msg('至少选一种联系方式');
					return;
				}
				if(this.phoneShow){
					if(this.phoneNumAc.length == 0){
						this.$util.msg('请添加手机区号');
						return;
					}
					if(this.phoneNum.length == 0){
						this.$util.msg('请添加手机号哦~');
						return;
					}
				}
				if(this.wxShow){
					if(this.wxCode.length == 0){
						this.$util.msg('请添加微信号哦~');
						return;
					}
					if(this.qrcodeImage.length == 0){
						this.$util.msg('请添加微信二维码哦~');
						return;
					}
				}
				if(this.whatsAppShow){
					if(this.whatsAppAc.length == 0){
						this.$util.msg('请输入WhatsApp区号');
						return;
					}
					if(this.whatsAppNum.length == 0){
						this.$util.msg('请添加WhatsApp号哦~');
						return;
					}
				}
				if(this.emailShow){
					if(this.emailNum.length == 0){
						this.$util.msg('请添加邮件(E-MAIL)哦~');
						return;
					}
				}
				var params = {};
				params.whatsAppShow = this.whatsAppShow ? '1' : '0';
				params.whatsAppNum = this.whatsAppNum;
				params.phoneNum = this.phoneNum;
				params.wxCode = this.wxCode;
				params.whatsAppAc = this.whatsAppAc;
				params.phoneNumAc = this.phoneNumAc;
				params.emailNum = this.emailNum;
				params.qrcodeImage = this.qrcodeImage;
				params.phoneShow = this.phoneShow ? '1' : '0';
				params.wxShow = this.wxShow ? '1' : '0';
				params.emailShow = this.wxShow ? '1' : '0';
				const initres = await queryUrlWithUInfo('/apis/modifyUserContact',params);
				if(initres.ec == '0'){
					this.$util.msg("修改成功");
				}
			},
			handleRemove(file, fileList) {
				console.log(fileList)
			},
			uploadFile(file){
				console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles',file.file);
				commitFileUrl("/apis/addImg",formData).then(res=>{
					// console.log(res)
					if(res.ec == '0'){
						this.qrcodeImage = res.path
					}
				});
			},
			async queryUserContact(){
				this.loading = true
				const initres = await queryUrlWithUInfo('/apis/queryUserContact',{queryUid:this.userInfo.fayanboId});
				if(initres.ec == '0'){
					this.phoneNumAc = initres.sduc_phoneAc;
					this.whatsAppAc = initres.sduc_whatsAppAc;
					this.wxShow = initres.sduc_wxShow == '1' ? true : false;
					this.phoneShow = initres.sduc_phoneShow == '1' ? true : false;
					this.whatsAppShow = initres.sduc_whatsAppShow == '1' ? true : false;
					this.emailShow = initres.sduc_emailShow == '1' ? true : false;
					this.emailNum = initres.sduc_emailNum;
					this.phoneNum = initres.sduc_phoneNum;
					this.wxCode = initres.sduc_wxCode;
					this.qrcodeImage = initres.sduc_wxQrcode;
					this.whatsAppNum = initres.sduc_whatsAppNum;
					this.fileList = [];
					this.fileList.push({fileId:'1',path:initres.sduc_wxQrcode,url:initres.sduc_wxQrcode});
					this.loading = false
				}
			},
			showWXQRcode(){
				this.$confirm('是否确定下单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.activeName == 'second'){
						this.vipCzH5();
					}else if(this.activeName == 'fifth'){
						this.jdCzH5();
					}else if(this.activeName == 'sixth'){
						this.guaranteeCz();
					}
				})
			},
			async guaranteeCz(){
				this.wxPayQRcode = true;
				var params = {};
				const initres = await postWithUInfo("/apis/guaranteeCz",params);
				if(initres.ec == '0'){
					this.createWxqrcode(initres.code_url,initres.outTradeNo);
				}else{
					this.$util.msg(initres.msg)
				}
			},
			async jdCzH5(){
				this.wxPayQRcode = true;
				var params = {};
				params.jdTpyeIndex = this.jdIndex;
				const initres = await postWithUInfo("/apis/JdCz",params);
				if(initres.ec == '0'){
					this.createWxqrcode(initres.code_url,initres.outTradeNo);
				}
			},
			async jdCzH5(){
				this.wxPayQRcode = true;
				var params = {};
				params.jdTpyeIndex = this.jdIndex;
				const initres = await postWithUInfo("/apis/JdCz",params);
				if(initres.ec == '0'){
					this.createWxqrcode(initres.code_url,initres.outTradeNo);
				}
			},
			async vipCzH5(){
				this.wxPayQRcode = true;
				var params = {};
				params.vipTpyeIndex = this.czType;
				const initres = await postWithUInfo("/apis/vipCzH5",params);
				if(initres.ec == '0'){
					this.createWxqrcode(initres.code_url,initres.outTradeNo);
				}
			},
			createWxqrcode(code,outTradeNo) {
				var vm = this;
				document.getElementById("wxqrcode").innerHTML = "";
				this.wxqrcode = new QRCode('wxqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.wxQrcoswloading = false
				this.checkInterval = setInterval(function(){
					vm.checkPayOrNot(outTradeNo)
				},2000);
			},
			showZFBQRcode(){
				this.$confirm('是否确定下单？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(this.activeName == 'second'){
						this.createZfbVipCz();
					}else if(this.activeName == 'fifth'){
						this.createZfbJdCz();
					}else if(this.activeName == 'sixth'){
						this.guaranteeZfbCz();
					}
				})
			},
			createAlipayqrcode(code,outTradeNo) {
				var vm = this;
				document.getElementById("alipayqrcode").innerHTML = "";
				this.alipayqrcode = new QRCode('alipayqrcode', {
					width: 200, //二维码宽度
					height: 200, // 二维码高度
					text: code, // 二维码内容，这里就是后端返回的字符串
					render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
				});
				this.alipayQrcoswloading = false
				this.checkInterval = setInterval(function(){
					vm.checkPayOrNot(outTradeNo)
				},2000);
			},
			async createZfbVipCz(){
				this.zfbPayQRcode = true
				var params = {};
				params.vipTpyeIndex = this.czType;
				const initres = await postWithUInfo("/apis/alipay/vipCz",params);
				if(initres.ec == '0'){
					this.createAlipayqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			async createZfbJdCz(){
				this.zfbPayQRcode = true
				var params = {};
				params.jdTpyeIndex = this.jdIndex;
				const initres = await postWithUInfo("/apis/alipay/JdCz",params);
				if(initres.ec == '0'){
					this.createAlipayqrcode(initres.code_url,initres.outTradeNo)
				}
			},
			async guaranteeZfbCz(){
				this.zfbPayQRcode = true
				var params = {};
				const initres = await postWithUInfo("/apis/alipay/guaranteeCz",params);
				if(initres.ec == '0'){
					this.createAlipayqrcode(initres.code_url,initres.outTradeNo)
				}else{
					this.$util.msg(initres.msg)
				}
			},
			async checkPayOrNot(outTradeNo){
				const initres = await postWithUInfo("/apis/checkPayOrNot",{outTradeNo:outTradeNo});
				if(initres.ec == '0'){
					this.wxPayQRcode = false
					this.zfbPayQRcode = false
					this.$util.msg('支付完成')
					clearInterval(this.checkInterval)
					this.queryPersonInfo();
					this.remainCostQuery();
				}
			},
			cancelBind(){
				this.walletForm.bindShowFlag = false
			},
			changeBindShow(){
				this.walletForm.bindShowFlag = true
			},
			changeTxAmtIndex(type){
				this.walletForm.txAmtIndex = type
			},
			changeTxType(type){
				this.walletForm.txType = type
			},
			changejdIndex(type){
				this.jdIndex = type;
			},
			handleBeforeUpload(e){
				// console.log(e)
				if(this.smForm.gzImgList.length + 1 > 3){
					this.$util.msg('超出最大上传数')
					return false;
				}
			},
			handleWorkPicRemove(e){
				// console.log(e)
			},
			handleExceed(e){
				this.$util.msg('超出最大上传数')
			},
			changeCzType(item){
				this.czType = item.sdvp_vipType
			},
			modyfyFirst(){
				this.firstModify = true
			},
			cancelFirst(){
				this.firstModify = false
			},
			uploadFileAvatar(file){
				// console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles',file.file);
				commitFileUrl("/apis/addImg",formData).then(res=>{
					if(res.ec == '0'){
						this.form.avatarUrl = res.path
					}
				});
			},
			uploadFilePassPort(file){
				// console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles',file.file);
				commitFileUrl("/apis/addImg",formData).then(res=>{
					if(res.ec == '0'){
						this.smForm.hzImgUrl = res.path
						this.passPortList = []
						this.smForm.passPortList.push({fileId:res.fileId,path:res.path})
					}
				});
			},
			uploadFileWorkPic(file){
				// console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles',file.file);
				commitFileUrl("/apis/addImg",formData).then(res=>{
					if(res.ec == '0'){
						this.smForm.gzImgList.push({fileId:res.fileId,path:res.path})
					}
				});
			},
			getVipPriceList(){
				var vm = this;
				queryUrl("/apis/getVipPriceList", {}).then(res => {
					if(res.ec == '0'){
						vm.vipPriceList = res.reList
					}
				});
			},
			myCertificationInfo(){
				var vm = this;
				queryUrl("/apis/myCertificationInfo", {}).then(res => {
					if(res.ec == '0'){
						vm.smForm.userRealName = res.sdu_realName;
						vm.smForm.userHzNum = res.sdu_icNum;
						vm.smForm.passPortList = JSON.parse(JSON.stringify(res.passportFileList));
						vm.smForm.gzImgList = JSON.parse(JSON.stringify(res.gzFileList));
					}
				});
			},
			queryMyWalletInfo(){
				var vm = this;
				doPost("/apis/queryMyWalletInfo", {}).then(res => {
					if(res.ec == '0'){
						vm.walletForm.remaining = res.remaining;
						vm.walletForm.withdrawApply = res.withdrawApply;
					}	
				});
			},
			getItemsList(){
				var vm = this;
				queryUrl("/apis/getItemsList", {code:'22001'}).then(res => {
					vm.txItemsList = res.reList
				});
			},
			getAlipayAccount(){
				var vm = this;
				queryUrl("/apis/getAlipayAccount", {}).then(res => {
					if(res.ec == '0'){
						vm.walletForm.suar_account = res.suar_account;
						vm.walletForm.suar_Name  = res.suar_Name;
						vm.walletForm.suar_icNum = res.suar_icNum;
						vm.walletForm.suar_phone = res.suar_phone;
					}
				});
			},
			remainCostQuery(){
				var vm = this;
				queryUrl("/apis/remainCostQuery", {}).then(res => {
					if(res.ec == '0'){
						vm.membercoins = res.membercoins;
					}
				});
			},
			queryJdPrice(){
				var vm = this;
				queryUrl("/apis/queryJdPrice", {}).then(res => {
					if(res.ec == '0'){
						vm.JdPriceList = res.reList;
					}
				});
			},
			saveBind(){
				var vm = this;
				if(this.walletForm.suar_Name.trim().length == 0){
					vm.$util.msg('请输入支付宝姓名');
					return false;
				}
				if(this.walletForm.suar_phone.trim().length == 0){
					vm.$util.msg('请输入手机号');
					return false;
				}
				if(this.walletForm.suar_icNum.trim().length == 0){
					vm.$util.msg('请输入身份证号');
					return false;
				}
				if(this.walletForm.suar_account.trim().length == 0){
					vm.$util.msg('请输入支付宝账号');
					return false;
				}
				var params = {};
				params.name = this.walletForm.suar_Name;
				params.phone = this.walletForm.suar_phone;
				params.icNum = this.walletForm.suar_icNum;
				params.alipayAccount = this.walletForm.suar_account;
				doPost("/apis/bindAlipayAccount", params).then(res => {
					if(res.ec == '0'){
						vm.$util.msg('修改成功');
						vm.walletForm.bindShowFlag = false;
						vm.getAlipayAccount();
					}	
				});
			},
			txApply(){
				var vm = this;
				var params = {};
				params.txAmtIndex = this.walletForm.txAmtIndex;
				params.txtype = this.walletForm.txType;
				doPost("/apis/applyTx", params).then(res => {
					if(res.ec == '0'){
						vm.$util.msg('提现申请成功，等待审核');
						vm.queryMyWalletInfo();
					}else{
						vm.$util.warnMsg(res.msg)
					}	
				});
			},
			uploadError(e){
				// console.log(e)
			},
			queryPersonInfo() {
				this.loading = true
				var vm = this;
				var params = {};
				queryUrl("/apis/getPersonInfo", params).then(res => {
					this.myInfo = res
					this.form.sexId = res.sdu_sex;
					var areaIndext = JSON.parse(res.sdu_areaId);
					this.$set(this.form.areaId,0,areaIndext[0])
					this.$set(this.form.areaId,1,areaIndext[1])
					this.form.avatarUrl = res.sdu_avatarUrl;
					this.form.brithDate = res.sdu_brithDate;
					this.form.marriageId = res.sdu_isMarriage;
					this.form.nickName = res.sdu_nickName;
					this.form.workName = res.sdu_work;
					this.loading = false
				});
			},
			handleClick(e) {
				if(e.name == 'first'){
					this.queryPersonInfo();
					this.firstModify = false
				}else if(e.name == 'second'){
					this.getVipPriceList();
				}else if(e.name == 'third'){
					this.myCertificationInfo();
				}else if(e.name == 'fourth'){
					this.queryMyWalletInfo();
					this.getItemsList();
					this.getAlipayAccount();
					this.walletForm.bindShowFlag = false
				}else if(e.name == 'sixth'){
					this.getGuarantee()
				}else if(e.name == 'seven'){
					this.queryUserContact()
				}
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			handleCityChange(e){
				// console.log(e)
			},
			onSubmitFirst(){
				var vm = this;
				this.loading = true
				var params = JSON.parse(JSON.stringify(this.form));
				params.areaId = JSON.stringify(this.form.areaId)
				queryUrl("/apis/modifyPersonInfo", params).then(res => {
					if(res.ec == '0'){
						this.loading = false
						vm.$util.msg('修改成功')
						vm.firstModify = false
					}
				});
			},
			saveCertif(){
				var vm = this;
				this.loading = true
				var params = {};
				params.realName = this.smForm.userRealName;
				params.icNum = this.smForm.userHzNum;
				params.passportFileList = JSON.stringify(this.smForm.passPortList);
				params.gzFileList = JSON.stringify(this.smForm.gzImgList);
				queryUrl("/apis/myCertification", params).then(res => {
					if(res.ec == '0'){
						this.loading = false
						vm.$util.msg('修改成功')
					}
				});
			},
			getGuarantee(){
				queryUrl("/apis/getGuaranteePrice", {}).then(res => {
					if(res.ec == '0'){
						this.guaranteeAmt = res.price
					}
				});
			},
			colseWxQrcode(){
				this.wxPayQRcode = false
			},
			colseAlipayQrcode(){
				this.zfbPayQRcode = false
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	h3 {
		text-align: left;
	}
	.contact {
		width: 600px;
		margin: 0 auto;
	}
	.normal_notice_msg {
		color: #A6A9AD;
		font-size: 12px;
		text-align: left;
		margin-top: 30px;
		line-height: 20px;
	}
	.guarantee {
		.notice_msg {
			text-align: left;
			margin-left: 200px;
			color: #c88854;
			font-size: 14px;
			line-height: 30px;
		}
		.tit {
			margin-bottom: 30px;
			font-weight: 700;
		}
	}
	.amt {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		font-size: 32px;
		color: #C88854;
		.unit {
			font-size: 20px;
			margin-left: 4px;
		}
	}
	.rest_btn {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: 20px;
		cursor: pointer;
	
		.icon_weixin {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/PAY-WEIXIN.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}
	
		.icon_zfb {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/zfb.png) no-repeat center;
			background-size: 100%;
			margin-right: 4px;
		}
	}
	
	.rest_btn:hover {
		color: #c88854;
	}
	.wxqrcode_warp {
		display: flex;
		justify-content: space-around;
		align-items: center;
		//padding: 20px 0px 20px 20px;
		position: relative;
		.icon_colse {
			width: 26px;
			height: 26px;
			background: url(../../assets/icon/close.png) center no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor: pointer;
		}
		.wxqrcode_warp_left {
			display: flex;
			flex-direction: column;
			.wxqrcode_bottom {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 20px;
				.sys-desc {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					flex-direction: column;
					font-size: 14px;
					color: #1d1b1b;
				}
				.sys_icon {
					background: url(../../assets/icon/saoyisao.png) center no-repeat;
					background-size: 100%;
					width: 30px;
					height: 30px;
					margin-right: 20px;
				}
			}
		}
		.wxqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/pc_icon_phone-bg.png) center no-repeat;
			background-size: 80%;
		}
		.zfbqrcode_warp_right {
			width: 200px;
			height: 200px;
			background: url(../../assets/icon/zfbScan.png) center no-repeat;
			background-size: 90%;
		}
	}
	.tx-notice {
		line-height: 30px;
		text-align: left;
		margin-top: 40px;
	}
	.active {
		background-color: #c8885438 !important;
		border: 1px solid #fd7507 !important;
	}
	.paytype {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 18px;
		padding: 12px 30px;
		margin-right: 20px;
		box-sizing: border-box;
		background-color: #e8e8e8;
		border-radius: 30px;
		.wx_icon {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/PAY-WEIXIN.png) no-repeat center;
			background-size: 100%;
			margin-right: 10px;
		}
		.alipay_icon {
			width: 30px;
			height: 30px;
			background: url(../../assets/icon/zfb.png) no-repeat center;
			background-size: 100%;
			margin-right: 10px;
		}
	}
	.bing_btn_warp {
		.input {
			width: 240px;
			height: 40px;
			background-color: #e8e8e8;
			line-height: 40px;
			border-radius: 40px;
		}
		.btn {
			width: 60px;
			background-color: #c88854;
			color: #fff;
			padding: 8px 16px;
			border-radius: 30px;
			cursor: pointer;
			margin-left: 20px;
		}
	}
	.wallet_head {
		.line {
			height: 50px;
			border-left: 1px solid #C88854;
			width: 1px;
		}
		.wallet_head_item {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 200px;
		}
		.tit {
			font-size: 20px;
			color: #333333;
			letter-spacing: 2px;
			margin-bottom: 10px;
		}
		
	}
	.container {
		width: 90%;
		margin: 0 auto;
		.btn_warp {
			margin-top: 50px;
			.btn {
				width: 100px;
				margin: 0 auto;
				background-color: #c88854;
				color: #fff;
				padding: 10px 20px;
				border-radius: 30px;
				cursor: pointer;
			}
		}
		.input_tit {
			font-size: 14px;
			font-weight: 500;
			color: #303133;
		}
		.flexone {
			flex: 1;
		}
		.input_warp {
			width: 240px;
			margin-right: 20px;
		}
		.input_min_warp {
			width: 140px;
			margin-right: 20px;
		}
		.cz_warp {
			margin-top: 30px;
			display: flex;
			justify-content:  flex-start;
			flex-wrap: wrap;
			.cz_item {
				height: 160px;
				width: 160px;
				border: 2px solid #D8D8D8;
				border-radius: 10px;
				margin-right: 20px;
				cursor: pointer;
				display: flex;
				justify-content: space-around;
				align-items: center;
				flex-direction: column;
				box-sizing: border-box;
				margin-bottom: 20px;
				.txt {
					font-size: 18px;
					color: #303133;
				}
				.amt {
					font-size: 22px;
					color: #F44336;
					.unit {
						font-size: 16px;
						margin-left: 4px;
					}
				}
				.oamt {
					color: #a09696;
					text-decoration: line-through;
					font-size: 14px;
				}
			}

		}

		.formwarp {
			margin-top: 50px;

			::v-deep .el-form {
				.el-form-item {
					width: 300px;

					.el-form-item__content {
						text-align: left;
					}

					.el-form-item__label {
						width: 120px;
					}
				}
			}
		}

		.warp {
			background: rgb(255, 255, 255);
			box-shadow: rgb(0 0 0 / 10%) 0px 1px 4px 0px;
			padding: 32px 20px 40px 20px;

			.padd {
				padding: 0 20px;
			}

			.avatar {
				width: 100px;
				height: 100px;

				img {
					width: 100%;
					height: 100%;
				}
			}
			.vip_info {
				height: 100px;
				padding-left: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				align-items: flex-start;
				.vip_userName {
					font-size: 18px;
					font-weight: 700
				}
				.vip_desc {
					font-size: 14px;
					color: #868181;
				}
			}
			.vip_icon {
				width: 100px;
				height: 100px;
				background: url(../../assets/icon/vipuser.png) no-repeat center;
				background-size: 100%;
			}
		}
	
		.hzsl_icon {
			width: 200px;
			height: 130px;
			background: url(../../assets/icon/hzsl.png) no-repeat center;
			background-size: 100%;
		}
	}
	.condition {
		text-align: left;
		font-size:16px;
		margin: 10px 20px 0px 20px;
		line-height: 30px;
		color: #666666;
		.head {
			font-size: 20px;
		}
	}
	::v-deep .wallet {
		.el-input {
			text-align: left;
			.el-input__inner {
				width: 280px;
			}	
		}
	}
	
	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	::v-deep .avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	::v-deep .avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 130px;
		height: 130px;
		line-height: 120px;
		text-align: center;
	}

	::v-deep .avatar {
		width: 130px;
		height: 130px;
		display: block;
	}
</style>
