<template>
	<div class="container">
		<div class="flex-between headwarp">
			<div class="head-item" @click="goChooseClassfiy()">1.选择类别</div>
			<div class="head-item active">2.填写信息</div>
			<div class="head-item">3.完成发布</div>
		</div>
		<div class="cont-body">
			
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
				reqType:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
		},
		methods:{
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
		}
	}
</style>
