<template>
	<div class="container">
		<el-page-header @back="goBack" content="修改">
		</el-page-header>
		<div class="cont-body" v-loading="searchLoading">
			<div class="content">
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">标签<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-radio-group v-model="tagIndex" class="radio_warp">
							<div v-for="(item,index) in delicacyTags" :key="item.key" class="radio_itm_warp">
								<el-radio :label="item.key">{{item.value}}</el-radio>
							</div>
						</el-radio-group>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">城市<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b">
						<el-cascader
						    v-model="areaId"
							:props="cascaderprops"
						    :options="citysList"
						    @change="handleCityChange">
						</el-cascader>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">价格<label v-if="CurrencySymbol.length > 0">({{CurrencySymbol}})</label></div>
						<div class="pub_coin_unit_desc">面向地区所在国家币种</div>
					</div>
					<div class="ctx-item-b" >
						<div class="hxs-warp">
							<el-input v-model="price" maxlength="8" placeholder="请输入价格" />
						</div>
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">餐厅名称<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入餐厅名称" v-model="restaurantName" maxlength="30" />
					</div>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">餐厅地址<label class="bishu">*</label></div>
					</div>
					<div class="ctx-item-b" >
						<el-input placeholder="请输入餐厅地址" v-model="activityPlace" maxlength="80" />
					</div>
				</div>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>
				<div class="ctx-item">
					<div class="ctx-item-t">
						<div class="ctx-item-head">详细说明<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc">(稍加修饰点缀才有吸引力哦~)</div>
					</div>
					<div class="ctx-item-b">
						<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入内容" show-word-limit></el-input>
					</div>
				</div>
				<div class="ctx-item marb200">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频<label class="bishu">*</label></div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile v-if="hasSearch" :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<div class="btn-foot">
					<div class="btn" @click="publish()">修改</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				reqType:'',
				multiArray:[],
				array:[],
				citysList:[],
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				tagIndex: '0',
				activityPlace:'',
				CurrencySymbol:'',
				price:'',
				areaId:'',
				reqNail:'',
				fileList:[],
				finalFileList:[],
				limitFilesNum:9,
				baseUrlpath:'',
				delicacyTags:[],
				restaurantName:'',
				reqNo:'',
				isShowConcact:false,
				isDetail:false,
				hasSearch:false,
				searchLoading:false,
				sdr_userId:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
			this.reqNo = this.$route.query.reqNo;
			this.citysList = this.$util.citysList;
			this.delicacyTags = this.$util.delicacyLebels;
			this.initReqInfo();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods:{
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async initReqInfo(){
				this.searchLoading = true
				const initres = await queryUrlWithUInfo('/apis/getReqInfo',{reqNo:this.reqNo});
				if(initres.ec == '0'){
					this.sdr_userId =  initres.reqInfo.sdr_userId;
					this.activityPlace = initres.reqInfo.sdr_delicacy_place;
					this.price = initres.reqInfo.sdr_delicacy_price;
					this.travelIndex = initres.reqInfo.sdr_delicacy_tag;
					this.restaurantName = initres.reqInfo.sdr_restaurant_name;
					this.isShowConcact = initres.reqInfo.sdr_isShowConcact == '1'?true:false;
					this.areaId = JSON.parse(initres.reqInfo.sdr_dqmodel);
					this.reqNail = initres.reqInfo.sdr_contents;
					this.isShowConcact = initres.reqInfo.sdr_isShowConcact == '1'?true:false;
					this.fileList = [];
					this.finalFileList = [];
					var attachlist = initres.reqInfo.attachlist;
					for(var index in attachlist){
						this.finalFileList.push({fileId:attachlist[index].sdra_fileid,path:attachlist[index].sdra_filePath,url:attachlist[index].sdra_filePath});
					}
					this.fileList = JSON.parse(JSON.stringify(this.finalFileList))
					this.searchLoading = false
					this.hasSearch = true
				}
			},
			async publish(){
				var vm = this;
				
				if(this.areaId.length == 0){
					this.$util.warnMsg('请选择城市');
					return false;
				}
				/* if(this.price.length == 0){
					this.$util.warnMsg('请输入价格');
					return false;
				} */
				if(this.reqNail.length == 0){
					this.$util.warnMsg('请输入详细说明');
					return false;
				}
				if(this.activityPlace.length == 0){
					this.$util.warnMsg('请输入餐厅地址');
					return false;
				}
				if(this.restaurantName.length == 0){
					this.$util.warnMsg('请输入餐厅名称');
					return false;
				}
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqNo = this.reqNo;
				params.reqType = "12";
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.dqModel = JSON.stringify(this.areaId);
				params.fileList = JSON.stringify(this.finalFileList);
				params.price = this.price;
				params.restaurantName = this.restaurantName;
				params.delicacyTag = this.tagIndex;
				params.activityPlace  = this.activityPlace;
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				const initres = await postWithUInfo('/apis/modifyReqPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("修改成功",{},()=>{
						vm.goBack()
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			handleCityChange(e){
				// console.log(e)
				this.queryCurrencySymbol();
			},
			async queryCurrencySymbol(){
				const initres = await queryUrlWithUInfo('/apis/queryCurrencySymbol',{'dqModel':JSON.stringify(this.areaId)});
				if(initres.ec == '0'){
					this.CurrencySymbol = initres.currencySymbol;
				}
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.radio_warp {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		.radio_itm_warp {
			margin: 0px 10px 10px 0px;
		}
	}
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
						.hxs-warp {
							width: 100px !important;
							margin-right: 10px;
						}
						
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
