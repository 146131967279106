<template>
	<div>
		<div class="container flex-start">
			<div class="search">
				<div class="flex-start">
					<div class="search_item">
						<div>提现方式：</div>
						<el-select v-model="reqType" placeholder="请选择">
							<el-option v-for="item in reqTypeList" :key="item.key" :label="item.value"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
					<div class="search_item">
						<div>审核状态：</div>
						<el-select v-model="reqOrderType" placeholder="请选择">
							<el-option v-for="item in orderTypeList" :key="item.key" :label="item.value"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_warp">
					<div class="btn" @click="queryBtn">查询</div>
				</div>
			</div>
			<div v-loading="loading" class="req-warp">
				<div v-if="tableData.length > 0">
					<div class="flex-start tx_desc">
						<div class="margr10">共{{totalCount}}笔提现申请待确认，</div>
						<div class="margr10">总计{{totalAmt}}元</div>
						<div>其中微信提现申请：</div>
						<div>共{{totalWxCount}}笔，</div>
						<div>总计{{totalWxAmt}}元，</div>
						<div>支付宝提现申请：</div>
						<div>共{{totalZfbCount}}笔，</div>
						<div>总计{{totalZfbAmt}}元</div>
					</div>
					<div class="req-warp">
						<el-table :data="tableData" border style="width: 100%">
							<el-table-column fixed align="center" label="申请人" width="200">
								<template slot-scope="scope">
									<div class="flex-center avatarwarp">
										<div class="avatar">
											<img :src="showAvatarUrl(scope.row.avatarUrl)"></img>
										</div>
										<div class="nickName">{{scope.row.nickName}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="txTypeName" align="center" label="提现方式" width="100">
							</el-table-column>
							<el-table-column prop="txAccount" align="center" label="账户" width="160">
							</el-table-column>
							<el-table-column prop="amt" align="center" label="申请金额 (元)" width="240">
								<template slot-scope="scope">
									<div>{{scope.row.amt}}</div> 
									<div class="flex-column-center" style="color: #FF0000;font-size: 12px;">
										<div>共收入{{scope.row.allInAmt}}元</div>
										<div>已提现（包含本次）{{scope.row.allOutAmt}}元</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column prop="opTime" align="center" label="申请时间" width="100">
							</el-table-column>
							<el-table-column align="center" prop="applySttName" label="审核状态" width="120">
								<template slot-scope="scope">
									<div>{{$util.getDisPlay(scope.row.sut_applyStt,$util.txCheckSttList)}}</div> 
									
								</template>
							</el-table-column>
							<el-table-column align="center" prop="sut_trans_status" label="转账信息" width="120">
								<template slot-scope="scope">
									<template v-if="scope.row.sut_trans_status == '1'">
										<div>转账成功</div>
									</template>
									<template v-else>
										<div v-if="scope.row.sut_errMsg">{{scope.row.sut_errMsg}}</div>
										<div v-else> -- </div>
									</template>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="140">
								<template slot-scope="scope">
									<div v-if="scope.row.sut_applyStt == '0'">
										<el-button @click="handleComfireClick(scope.row)" type="text" size="small" >审核通过</el-button>
										<el-button type="text" size="small" @click="handleRejectClick(scope.row)" >审核拒绝</el-button>
									</div>
									<div v-else>
										<el-button type="text" size="small" >--</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="pagination_warp">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage1"
							:page-size="pageSize" layout="total, prev, pager, next" :total="total">
						</el-pagination>
					</div>
				</div>
				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import {
		queryUrl,
		doPost,
		commitFileUrl,queryUrlWithUInfo,postWithUInfo
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	export default {
		data() {
			return {
				currentPage1: 1,
				tableData: [],
				total: 0,
				pageSize: 9,
				baseUrlpath: '',
				reqType: 'all',
				reqTypeList: [],
				reqOrderType: 'all',
				orderTypeList: [],
				loading: false,
				totalAmt:0,
				totalCount:0,
				totalWxAmt:0,
				totalWxCount:0,
				totalZfbAmt:0,
				totalZfbCount:0
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			this.reqTypeList = JSON.parse(JSON.stringify(this.$util.txTypeList));
			this.reqTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.orderTypeList = JSON.parse(JSON.stringify(this.$util.txCheckSttList));
			this.orderTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.query();
			this.getTxAppLyCountAndAmt();
		},
		methods: {
			handleCurrentChange(e) {
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			handleComfireClick(info) {
				var vm = this;
				this.$confirm('是否确定审核通过？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					vm.dealputOnReq(info);
				})
			},
			async dealputOnReq(info) {
				this.loading = true
				var params = {};
				params.flowNo = info.sut_id
				const initres = await doPost("/apis/comfireTxAppLy", params);
				if (initres.ec == '0') {
					this.$util.msg("审核通过成功");
					this.query();
				}else{
					this.$util.msg("操作失败");
					this.query();
				}
				this.loading = false
			},
			handleRejectClick(info) {
				var vm = this;
				this.$confirm('是否确定审核拒绝？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					vm.dealReject(info);
				})
			},
			async dealReject(info) {
				this.loading = true
				var vm = this;
				var params = {};
				params.txsid = this.sut_id
				const initres = await doPost("/apis/txExamineReject", params);
				if (initres.ec == '0') {
					this.$util.msg("拒绝成功");
					this.query();
				}
				this.loading = false
			},
			queryBtn() {
				this.currentPage1 = 1;
				this.query();
				this.getTxAppLyCountAndAmt()
			},
			query() {
				this.loading = true
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				params.txType = vm.reqType;
				queryUrl("/apis/getTxAppLyList", params).then(res => {
					this.loading = false
					vm.tableData = res.reList;
					vm.total = res.totalNum;
				});
			},
			async getTxAppLyCountAndAmt() {
				const initres = await queryUrlWithUInfo("/apis/getTxAppLyCountAndAmt",{});
				if(initres.ec == '0'){
					this.totalAmt = initres.totalAmt;
					this.totalCount = initres.totalCount;
					this.totalWxAmt = initres.totalWxAmt;
					this.totalWxCount = initres.totalWxCount;
					this.totalZfbAmt = initres.totalZfbAmt;
					this.totalZfbCount = initres.totalZfbCount;
				}
			},
			goToDetail(itemInfo) {
				this.$router.push({
					name: 'reqDetail',
					query: {
						reqNo: itemInfo.sdr_reqId
					}
				});
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.tx_desc {
		line-height: 80px;
		color: red;
		font-size: 16px;
	}
	.req-warp {
		width: 100%;

		.avatarwarp {
			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickName {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		align-items: flex-start;
		flex-direction: column;

		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
			width: 100%;
		}

		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.search {
			margin-top: 20px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;

			.search_item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.search_item:not(:first-child) {
				margin-left: 26px;
			}

			.btn_warp {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;

				.btn {
					background-color: #C88854;
					color: #FFFFFF;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}

				.rest_btn {
					border: 1px solid #C88854;
					color: #C88854;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.req-item {
		border-radius: 8px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		overflow: auto;
		margin-right: 12px;
		margin-bottom: 12px;
		padding: 20px 20px;
	}
</style>
