<template>
	<div>
		<div class="container flex-start">
			<div class="search">
				<div class="flex-start">
					<div class="search_item">
						<div>需求状态：</div>
						<el-select v-model="reqOrderType" placeholder="请选择">
							<el-option v-for="item in orderTypeList" :key="item.key" :label="item.value"
								:value="item.key">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="btn_warp">
					<div class="btn" @click="queryBtn">查询</div>
				</div>
			</div>
			<div v-loading="loading" class="req-warp">
				<div v-if="tableData.length > 0">
					<div class="req-warp">
						<el-table :data="tableData" border style="width: 100%">
							<el-table-column fixed align="center" label="申请人" width="200">
								<template slot-scope="scope">
									<div class="flex-center avatarwarp">
										<div class="avatar">
											<img :src="showAvatarUrl(scope.row.sdu_avatarUrl)"></img>
										</div>
										<div class="nickName">{{scope.row.sdu_nickName}}</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column align="center" label="需求附件" width="160">
								<template slot-scope="scope">
									<div v-if="scope.row.reqAttachList.length == 0">--</div>
									<el-image v-else style="width: 30px; height: 30px"
										:src="baseUrlpath + scope.row.reqAttachList[0].filePath" @error="imgOnError"
										:preview-src-list="$util.previewSrcList(scope.row.reqAttachList)">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="sdr_contents" align="center" label="发布内容" width="520">
							</el-table-column>
							<el-table-column prop="sdu_work" align="center" label="举报内容" width="120">
								<template slot-scope="scope">
									<el-popover placement="right" width="400" trigger="click">
										<el-timeline>
											<el-timeline-item v-for="(activity, index) in scope.row.reqTipsList"
												:key="index" :timestamp="activity.addtime">
												{{activity.srt_contents}}
											</el-timeline-item>
										</el-timeline>
										<el-button type="text" slot="reference" size="small">查看</el-button>
									</el-popover>
								</template>
							</el-table-column>
							<el-table-column align="center" prop="sdr_stt" label="需求状态" width="120">
								<template slot-scope="scope">
									<div>{{$util.getDisPlay(scope.row.sdr_stt,$util.putOnSttList)}}</div>
								</template>
							</el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="100">
								<template slot-scope="scope">
									<div v-if="scope.row.sdr_stt == '0'">
										<el-button @click="putDownReq(scope.row)" type="text" size="small">下架
										</el-button>
									</div>
									<div v-if="scope.row.sdr_stt == '1'">
										<el-button @click="putOnReq(scope.row)" type="text" size="small">上架
										</el-button>
									</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="pagination_warp">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage1"
							:page-size="pageSize" layout="total, prev, pager, next" :total="total">
						</el-pagination>
					</div>
				</div>
				<div v-else class="empty-msg">
					<el-empty description="暂无内容"></el-empty>
				</div>
			</div>
		</div>
		<div>
			<el-image ref="previewImg" style="width: 0px; height: 0px" :src="imgSrcUrl" @error="imgOnError"
				:preview-src-list="imgPreUrls">
			</el-image>
		</div>
		<el-dialog title="提示" :visible.sync="rejectDialogVisible" width="30%" center>
			<el-input type="textarea" :rows="4" placeholder="输入下架原因" v-model="rejectMsg" maxlength='100'
				show-word-limit></el-input>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancelReject()">取 消</el-button>
				<el-button type="primary" @click="dealReject()">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		queryUrl,
		doPost
	} from '@/api/index';
	import {
		Toast
	} from 'mint-ui';
	export default {
		data() {
			return {
				currentPage1: 1,
				tableData: [],
				total: 0,
				pageSize: 9,
				baseUrlpath: '',
				reqType: 'all',
				reqTypeList: [],
				reqOrderType: 'all',
				orderTypeList: [],
				loading: false,
				defalutImg: require('@/assets/icon/noimg.jpg'),
				imgSrcUrl: '',
				imgPreUrls: [],
				rejectDialogVisible: false,
				rejectMsg: '',
				dealingId: ''
			}
		},
		created() {
			this.baseUrlpath = this.baseUrl;
			this.reqTypeList = JSON.parse(JSON.stringify(this.$util.reqList));
			this.reqTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.orderTypeList = JSON.parse(JSON.stringify(this.$util.putOnSttList));
			this.orderTypeList.unshift({
				'key': 'all',
				'value': '不限'
			})
			this.query();
		},
		methods: {
			handleCurrentChange(e) {
				// console.log(e)
				this.currentPage1 = e;
				this.tableData = [];
				this.total = 0;
				this.query()
			},
			viewWorkPicture(attalist) {
				// console.log(attalist)
				if (attalist == undefined || attalist.length == 0) {
					this.$util.warnMsg('无附件');
				} else {
					this.imgSrcUrl = attalist[0].sdua_filePath
					this.imgPreUrls = []
					for (var index in attalist) {
						this.imgPreUrls.push(attalist[index].sdua_filePath)
					}
					this.$refs.previewImg.showViewer = true;
				}
			},
			imgOnError(e) {
				const img = e.srcElement;
				img.src = this.defalutImg;
				img.onerror = null
			},
			handleComfireClick() {

			},
			queryBtn() {
				this.currentPage1 = 1;
				this.query();
			},
			query() {
				this.loading = true
				var vm = this;
				var params = {};
				params.currentPage = vm.currentPage1;
				params.pageSize = vm.pageSize;
				queryUrl("/apis/queryReqTipsList", params).then(res => {
					this.loading = false
					vm.tableData = res.reqList;
					vm.total = res.totalNum;
				});
			},
			goToDetail(itemInfo) {
				this.$router.push({
					name: 'reqDetail',
					query: {
						reqNo: itemInfo.sdr_reqId
					}
				});
			},
			showAvatarUrl(avatarUrl) {
				if (avatarUrl) {
					if (avatarUrl.indexOf('http') >= 0) {
						return avatarUrl;
					} else {
						return this.baseUrlpath + avatarUrl;
					}
				}
			},
			putDownReq(info) {
				var vm = this;
				this.$confirm('是否确定下架？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					vm.rejectCertification(info);
				})
			},
			putOnReq(info) {
				var vm = this;
				this.$confirm('是否确定上架？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					vm.dealputOnReq(info);
				})
			},
			async dealputOnReq(info) {
				var params = {};
				params.reqNo = info.sdr_reqId
				const initres = await doPost("/apis/putOnReq", params);
				if (initres.ec == '0') {
					this.$util.msg("上架成功");
					this.query();
				}
			},
			cancelReject() {
				this.rejectDialogVisible = false
				this.rejectMsg = ""
			},
			async dealReject() {
				if (this.rejectMsg.length == 0) {
					this.$util.warnMsg("请输入拒绝原因");
					return;
				}
				var vm = this;
				var params = {};
				params.reqNo = this.dealingId
				params.rejectMsg = this.rejectMsg;
				const initres = await doPost("/apis/putDownReq", params);
				if (initres.ec == '0') {
					this.$util.msg("下架成功");
					this.query();
				}
			},
			rejectCertification(info) {
				this.rejectDialogVisible = true
				this.dealingId = info.sdr_reqId
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.req-warp {
		width: 100%;

		.avatarwarp {
			.avatar {
				width: 30px;
				height: 30px;
				overflow: hidden;
				border-radius: 50px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.nickName {
				font-size: 12px;
				padding-left: 26px;
				width: 100px;
				text-align: left;
			}
		}
	}

	.container {
		width: 90%;
		margin: 0 auto;
		align-items: flex-start;
		flex-direction: column;

		.pagination_warp {
			padding: 0 20px 20px 0;
			margin-top: 60px;
			width: 100%;
		}

		.empty-msg {
			flex: 1;
			height: calc(100vh - 70px);
			display: flex;
			justify-content: center;
			width: 100%;
		}

		.search {
			margin-top: 20px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;

			.search_item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}

			.search_item:not(:first-child) {
				margin-left: 26px;
			}

			.btn_warp {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;

				.btn {
					background-color: #C88854;
					color: #FFFFFF;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}

				.rest_btn {
					border: 1px solid #C88854;
					color: #C88854;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}

	.req-item {
		border-radius: 8px;
		box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.06);
		overflow: auto;
		margin-right: 12px;
		margin-bottom: 12px;
		padding: 20px 20px;
	}
</style>
