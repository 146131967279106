<template>
</template>

<script>
	import {
		doPost
	} from '@/api/index';
	export default {
		data(){
			return {
				reqNo:'',
				lastIp:'',
				opTime:''
			}
		},
		beforeCreate(){
			document.getElementById('app').style.display="none";
		},
		created(){
			var vm = this;
			this.reqNo = this.$route.query.reqNo;
			this.lastIp = this.$route.query.lastIp;
			this.opTime = this.$route.query.opTime;
			// console.log(this.$route.query)
			var params = {};
			params.reqNo = this.reqNo;
			params.payerclientip = this.lastIp;
			params.opTime = this.opTime;
			doPost("/apis/transactionsH5", params).then(res => {
				if(res.ec == '0'){
					window.location.href = res.h5_url;
				}else{
					vm.$util.warnMsg(res.msg);
				}
			})
		},
		mounted() {
		  
		},
		methods:{
			
		}
	}
</script>

<style>
</style>
