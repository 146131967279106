<template>
	<div class="container">
		<div class="search">
			<div>
				<div class="search_item">
					<div>状态：</div>
					<el-select v-model="reqType" placeholder="请选择">
					    <el-option
					      v-for="item in reqTypeList"
					      :key="item.key"
					      :label="item.value"
					      :value="item.key">
					    </el-option>
					</el-select>
				</div>
			</div>
			<div class="btn_warp">
				<div class="btn" @click="queryBtn">查询</div>
			</div>
		</div>
		<div v-if="tableData.length > 0">
			<el-table
			    :data="tableData"
			    border
			    style="width: 100%" v-loading="loading">
			    <el-table-column
			      fixed
			      prop="sdu_nickName"
			      label="昵称"
				  width="180"
			    >
			    </el-table-column>
			    <el-table-column
			      prop="sdup_addTime"
			      label="时间"
				  width="180"
			    >
			    </el-table-column>
			    <el-table-column
			      prop="sdup_contents"
			      label="内容"
			    >
			    </el-table-column>
				<el-table-column
				  prop="sdup_contents"
				  label="附件"
				  width="140"
				>
					<template slot-scope="scope" v-if="scope.row.attachlist.length > 0">
						<img :src="scope.row.attachlist[0].sdpa_filePath"/>
					</template>
				</el-table-column>
			    <el-table-column
			      fixed="right"
			      label="操作"
				  width="160"
			    >
			      <template slot-scope="scope">
			        <el-button @click="handleClick(scope.row)" type="text" size="small">采纳</el-button>
			        <el-button type="text" size="small">忽略</el-button>
			      </template>
			    </el-table-column>
			  </el-table>
		</div>
		<div v-else class="empty-msg">
			<el-empty description="暂无内容"></el-empty>
		</div>
	</div>
</template>

<script>
	import {queryUrl,doPost} from '@/api/index';
	export default {
		data() {
			return {
				loading:false,
				tableData: [],
				baseUrlpath:'',
				reqTypeList:[
					{'key':'all','value':'全部'},
					{'key':'0','value':'未处理'},
					{'key':'1','value':'已采纳'},
					{'key':'2','value':'忽略'}
				],
				reqType:'all'
			}
		},
		created() {
			// console.log(window.location)
			this.baseUrlpath = window.location.origin;
			this.query();
		},
		methods: {
			queryBtn(){
				this.query()
			},
			query(){
				this.loading = true
				var vm = this;
				var params = {};
				params.posalStt = this.reqType;
				queryUrl("/apis/queryProposals",params).then(res=>{
					this.loading = false;
				    vm.tableData = res.relist;
				    //vm.total = res.totalNum;
				});
			},
			handleClick(row) {
				// console.log(row);
			}
		}
	}
</script>

<style scoped="scoped" lang="scss">
	.container {
		width: 90%;
		margin: 0 auto;
		img {
			width: 30px;
			height: 30px;
		}
		.search {
			margin-top: 20px;
			margin-bottom: 30px;
			display: flex;
			align-items: center;
			.search_item {
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
			.btn_warp {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				margin-left: 20px;
				.btn {
					background-color: #C88854;
					color: #FFFFFF;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
				.rest_btn {
					border: 1px solid #C88854;
					color: #C88854;
					width: 80px;
					margin: 0 10px;
					padding: 6px 0;
					border-radius: 20px;
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}
</style>
