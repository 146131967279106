<template>
	<div class="container">
		<el-page-header @back="goBack" content="修改">
		</el-page-header>
		<div class="cont-body" v-loading="searchLoading">
			<div class="content hundvh">
				<div class="ctx-item flex-center">
					<el-radio-group v-model="inviteModel" @change="radioCmChange">
						<el-radio v-for="(item,index) in array" :key="item.key" :label="item.key">
							<label v-if="item.key == '0' || item.key == '1'">我要</label>
							<label>{{item.value}}</label>
						</el-radio>
					</el-radio-group>
				</div>
				<template v-if="inviteModel.startsWith('nkcor')">
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">名称<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入名称" v-model="storeCorName" maxlength="30" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">地址<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-input placeholder="请输入地址" v-model="storeCorAddr" maxlength="80" />
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">简介<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="storeCorDesc" :autosize="{ minRows: 4}" maxlength="200" placeholder="请输入简介！" show-word-limit></el-input>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">面向地区<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b">
							<el-cascader
							    v-model="areaId"
								:props="cascaderprops"
							    :options="citysList"
							    @change="handleCityChange">
							</el-cascader>
						</div>
					</div>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">岗位标签<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-select v-model="inviteTypeIndex" placeholder="请选择">
								<el-option
									v-for="item in inviteLabelList"
									:key="item.key"
									:label="item.value"
									:value="item.key">
								</el-option>
							</el-select>
						</div>
					</div>
					<!-- <div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head">薪资模式<label class="bishu">*</label></div>
						</div>
						<div class="ctx-item-b" >
							<el-radio-group v-model="fyIndex">
								<el-radio :label="0">面议</el-radio>
								<el-radio :label="1">薪资范围</el-radio>
							</el-radio-group>
						</div>
					</div> -->
					<div class="ctx-item" v-if="fyIndex == '1'">
						<div class="ctx-item-t">
							<div class="ctx-item-head">薪资范围<label v-if="CurrencySymbol.length > 0">({{CurrencySymbol}})</label><label class="bishu">*</label></div>
							<div class="pub_coin_unit_desc">面向地区所在国家币种</div>
						</div>
						<div class="ctx-item-b" >
							<div class="flex-start">
								<el-input placeholder-class="t-center" v-model="startSalary" maxlength="6" placeholder="起始薪资" />
								<label class="marlr10">~</label>
								<el-input placeholder-class="t-center" v-model="endSalary" maxlength="6" placeholder="结束薪资" />
							</div>
						</div>
					</div>
					<template v-if="inviteModel == '0'">
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">公司名称<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入企业名称" v-model="corName" maxlength="30"/>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">公司地址<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入公司地址" v-model="corAddr" maxlength="80"/>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">公司简介<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b">
								<el-input type="textarea" v-model="corDesc" :autosize="{ minRows: 4}" maxlength="2000" placeholder="请输入公司简介" show-word-limit></el-input>
							</div>
						</div>
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">招聘职位<label class="bishu">*</label></div>
							</div>
							<div class="ctx-item-b" >
								<el-input placeholder="请输入招聘职位" v-model="jobName" maxlength="30"/>
							</div>
						</div>
					</template>
					<div class="ctx-item">
						<div class="ctx-item-t">
							<div class="ctx-item-head" v-if="inviteModel == '0'">工作内容<label class="bishu">*</label></div>
							<div class="ctx-item-head" v-else>个人技能和优势<label class="bishu">*</label></div>
							<div class="ctx-item-headdesc">（尽可能详细）</div>
						</div>
						<div class="ctx-item-b">
							<el-input type="textarea" v-model="workNail" :autosize="{ minRows: 4}" maxlength="2000" placeholder="请输入" show-word-limit></el-input>
						</div>
					</div>
					<template v-if="inviteModel == '0'">
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">招聘要求/待遇<label class="bishu">*</label></div>
								<div class="ctx-item-headdesc">（尽可能详细）</div>
							</div>
							<div class="ctx-item-b">
								<el-input type="textarea" v-model="reqNail" :autosize="{ minRows: 4}" maxlength="2000" placeholder="请输入内容" show-word-limit></el-input>
							</div>
						</div>
					</template>
					<template v-if="inviteModel == '1'">
						<div class="ctx-item">
							<div class="ctx-item-t">
								<div class="ctx-item-head">上传简历</div>
							</div>
							<div class="ctx-item-b">
								<el-upload
								  action="123"
								  :http-request="uploadFile"
								  :limit="1"
								  :file-list="jlfileList">
								  <el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
							</div>
						</div>
					</template>
				</template>
				<div class="ctx-item inlinediv">
					<pickContact ref="pickContact" :queryUid="userInfo.fayanboId" :baseUrlpath="baseUrlpath" @changeConcactShow="changeConcactShow" :isShowConcact="isShowConcact" :isDetail="isDetail"></pickContact>
				</div>
				<div class="ctx-item" v-if="inviteModel == '0' || inviteModel.startsWith('nk')">
					<div class="ctx-item-t">
						<div class="ctx-item-head">上传照片/视频</div>
						<div class="ctx-item-headdesc"><label>({{finalFileList.length}}/{{limitFilesNum}})</label></div>
					</div>
					<div class="ctx-item-b">
						<uploadFile :baseUrlpath="baseUrlpath" :fileList="fileList" @addOrMulitiFileList="addOrMulitiFileList"></uploadFile>
					</div>
				</div>
				<div class="btn-foot">
					<div class="btn" @click="publish()">修改</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters,mapActions} from 'vuex'
	import uploadFile from '@/components/upload/uploadFile.vue'
	import pickContact from '@/components/pickContact.vue'
	import {queryUrl,doPost,commitFileUrl,queryUrlWithUInfo,postWithUInfo} from '@/api/index';
	import { Toast } from 'mint-ui';
	export default {
		components:{
		  uploadFile,pickContact
		},
		data(){
			return {
				cascaderprops:{
					value:'id',
					label:'name',
					children:'child'
				},
				reqNail:'',
				storeCorName:'',
				storeCorlatitude:'',
				storeCorlongitude:'',
				storeCorAddr:'',
				storeCorDesc:'',
				fyIndex:1,
				areaId:'',
				dbIndex:'',
				startSalary:'',
				endSalary:'',
				corName:'',
				corAddr:'',
				corDesc:'',
				indicatorStyle: `height: 50px;`,
				maskStyle:``,
				jobName:'',
				dqList:[],
				fileList:[],
				finalFileList:[],
				limitFilesFlg:true,
				limitFilesNum:9,
				baseUrlpath:'',
				citysList:[],
				array:[],
				inviteLabelList:[],
				inviteTypeIndex:'',
				isShowConcact:false,
				isDetail:false,
				hasSearch:false,
				searchLoading:false,
				sdr_userId:'',
				reqNo:'',
				inviteModel:'',
				workNail:'',
				ismultiple:false,
				jianLiPath:'',
				jlfileList:[],
				CurrencySymbol:''
			}
		},
		created() {
			// console.log(this.$route.query)
			this.reqType = this.$route.query.reqType;
			this.citysList = this.$util.citysList;
			this.inviteLabelList = this.$util.inviteLabels;
			this.array = this.$util.inviteModelList;
			this.reqNo = this.$route.query.reqNo;
			this.initReqInfo();
		},
		computed: {
			...mapGetters(['userInfo'])
		},
		methods:{
			uploadFile(file){
				console.log(file)
				var vm = this;
				let formData = new FormData();
				formData.append('multipartfiles',file.file);
				commitFileUrl("/apis/addImg",formData).then(res=>{
					// console.log(res)
					if(res.ec == '0'){
						this.jianLiPath = res.path
						//this.jlfileList = []
						//this.jlfileList.push({fileId:'1',path:res.path,url:res.path,name:this.jianLiPathFormat(res.path)});
					}
				});
			},
			jianLiPathFormat(jianLiPath){
				var filaname = jianLiPath.substring(jianLiPath.lastIndexOf('/')+1) ;
				var prefx = filaname.substring(filaname.lastIndexOf("."));
				var tempName = filaname.substr(0,filaname.lastIndexOf("."));
				if(tempName.length > 10){
					return tempName.substr(0,10)+'...'+prefx
				}else{
					return filaname
				}
			},
			radioCmChange(e){
				this.inviteModel = e
			},
			changeConcactShow(val){
				this.isShowConcact = val
			},
			goBack() {
				this.$util.goBack();
			},
			async initReqInfo(){
				this.searchLoading = true
				const initres = await queryUrlWithUInfo('/apis/getReqInfo',{reqNo:this.reqNo});
				if(initres.ec == '0'){
					this.sdr_userId =  initres.reqInfo.sdr_userId;
					this.inviteModel = initres.reqInfo.sdr_invite_model;
					this.workNail = initres.reqInfo.sdr_invite_workNail;
					this.storeCorName = initres.reqInfo.sdr_storeCorName;
					this.storeCorlatitude = initres.reqInfo.sdr_storeCorlatitude;
					this.storeCorlongitude = initres.reqInfo.sdr_storeCorlongitude;
					this.storeCorAddr = initres.reqInfo.sdr_storeCorAddr;
					this.storeCorDesc = initres.reqInfo.sdr_storeCorDesc;
					this.jianLiPath = initres.reqInfo.sdr_invite_jianLiPath;
					this.jlfileList = []
					this.jlfileList.push({fileId:'1',path:initres.reqInfo.sdr_invite_jianLiPath,url:initres.reqInfo.sdr_invite_jianLiPath,name:this.jianLiPathFormat(initres.reqInfo.sdr_invite_jianLiPath)});
					this.corAddr = initres.reqInfo.sdr_invite_corAddr;
					this.corDesc = initres.reqInfo.sdr_invite_corDesc;
					this.inviteTypeIndex = initres.reqInfo.sdr_invite_inviteTypeIndex;
					this.corName = initres.reqInfo.sdr_invite_corName;
					this.jobName = initres.reqInfo.sdr_invite_jobName;
					this.startSalary = initres.reqInfo.salary_startRange;
					this.endSalary = initres.reqInfo.salary_endRange;
					this.areaId = this.$util.parsejson(initres.reqInfo.sdr_dqmodel);
					this.fyIndex = parseInt( initres.reqInfo.sdr_salary_offerType);
					this.reqNail = initres.reqInfo.sdr_contents;
					console.log('detail')
					this.isShowConcact = initres.reqInfo.sdr_isShowConcact == '1'?true:false;
					this.fileList = [];
					this.finalFileList = [];
					var attachlist = initres.reqInfo.attachlist;
					for(var index in attachlist){
						this.finalFileList.push({fileId:attachlist[index].sdra_fileid,path:attachlist[index].sdra_filePath,url:attachlist[index].sdra_filePath});
					}
					this.fileList = JSON.parse(JSON.stringify(this.finalFileList))
					this.searchLoading = false
					this.hasSearch = true
				}
			},
			async publish(){
				var vm = this;
				if(this.sfCarModel.startsWith('nk')){
					if(this.sfCarModel.startsWith('nkcor')) {
						if(this.storeCorName.length == 0){
							this.$util.warnMsg('请输入名称');
							return false;
						}
						if(this.storeCorAddr.length == 0){
							this.$util.warnMsg('请输入地址');
							return false;
						}
						if(this.storeCorDesc.length == 0){
							this.$util.warnMsg('请输入简介');
							return false;
						}
					}
					if(this.finalFileList.length > 0){
						this.$util.warnMsg('请上传图片');
						return false;
					}
				}else{
					if(this.fyIndex.length == 0){
						this.$util.msg('请选择薪资模式');
						return false;
					}
					if(this.areaId.length == 0){
						this.$util.msg('请选择面向地区');
						return false;
					}
					if(this.inviteTypeIndex.length == 0){
						this.$util.msg('请选择岗位标签');
						return false;
					}
					if(this.inviteModel == '0'){
						if(this.corName.length == 0){
							this.$util.msg('请输入企业名称');
							return false;
						}
						if(this.corAddr.length == 0){
							this.$util.msg('请输入企业地址');
							return false;
						}
						if(this.corDesc.length == 0){
							this.$util.msg('请输入企业简介');
							return false;
						}
						if(this.jobName.length == 0){
							this.$util.msg('请输入招聘岗位');
							return false;
						}
						if(this.workNail.length == 0){
							this.$util.msg('请输入工作内容');
							return false;
						}
						if(this.reqNail.length == 0){
							this.$util.msg('请输入招牌要求');
							return false;
						}
						if(this.finalFileList.length == 0){
							this.$util.msg('请上传公司照片');
							return false;
						}
					}
					if(this.inviteModel == '1'){
						if(this.workNail.length == 0){
							this.$util.msg('请输入个人技能和优势');
							return false;
						}
						/* if(this.jianLiPath.length == 0){
							this.$util.msg('请上传个人简历');
							return false;
						} */
					}
					if(this.fyIndex == '1'){
						if(this.startSalary.length == 0 || this.endSalary.length == 0){
							this.$util.warnMsg('请输入薪资范围');
							return false;
						}
					}
				}
				vm.$util.showMyLoading('加载中');
				var params = {};
				params.reqNo = this.reqNo;
				params.reqType = "3";
				params.storeCorName = this.storeCorName;
				params.storeCorAddr = this.storeCorAddr;
				params.storeCorDesc = this.storeCorDesc;
				params.storeCorlatitude = this.storeCorlatitude;
				params.storeCorlongitude = this.storeCorlongitude;
				params.inviteModel = this.inviteModel;
				params.fyIndex = this.fyIndex;
				params.reqNail = this.$util.textFormat(this.reqNail);
				params.workNail = this.workNail;
				params.jianLiPath = this.jianLiPath;
				params.corAddr = this.corAddr;
				params.corDesc = this.corDesc;
				params.inviteTypeIndex = this.inviteTypeIndex;
				params.corName = this.corName;
				params.jobName = this.jobName;
				params.dqModel = JSON.stringify(this.areaId);
				params.fileList = JSON.stringify(this.finalFileList);
				params.startSalary = this.startSalary;
				params.endSalary = this.endSalary;
				params.isShowConcact = this.isShowConcact ? '1' : '0';
				const initres = await postWithUInfo('/apis/modifyReqPost',params);
				if(initres.ec == '0'){
					vm.$util.hideMyLoading();
					this.$util.msg("修改成功",{},()=>{
						vm.goBack()
					});
				}
			},
			goChooseClassfiy(){
				this.$router.push({
				  name: 'publishIndex',
				  query: {}
				});
			},
			addOrMulitiFileList(params){
				// console.log(params)
				if(params.type == '1'){
					this.finalFileList.push({fileId:params.file.fileId,path:params.file.path,url:params.file.path,thumbPath:params.file.thumbPath})
					// console.log(this.fileList)
				}else if(params.type == '2'){
					this.finalFileList = this.finalFileList.filter((item,index) => {
						return item.fileId != params.file.fileId
					});
					// console.log(this.fileList)
				}
			},
			handleCityChange(e){
				// console.log(e)
				this.queryCurrencySymbol()
			},
			async queryCurrencySymbol(){
				const initres = await queryUrlWithUInfo('/apis/queryCurrencySymbol',{'dqModel':JSON.stringify(this.areaId)});
				if(initres.ec == '0'){
					this.CurrencySymbol = initres.currencySymbol;
				}
			}
		}
	}
</script>

<style lang="scss" scoped="scoped">
	.container {
		width: 90%;
		margin: 0 auto;
		box-shadow: 0px 4px 16px 1px rgb(0 0 0 / 6%);
		height: 100%;
		padding: 10px 20px;
		.headwarp {
			.head-item {
				width: 30%;
				text-align: left;
				line-height: 50px;
				border-bottom: 2px solid #F1F1F1;
			}
			.active {
				border-bottom: 2px solid #C88854;
			}
		}
		.cont-body {
			margin-top: 20px;
			//overflow: scroll;
			min-height: calc(100% - 100px);
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				.ctx-item {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 10px 0;
					width: 600px;
					.bishu {
						color: #FF0000;
						margin-left: 4px;
					}
					.ctx-item-t {
						margin-right: 10px;
						.ctx-item-head {
							line-height: 40px;
							width: 160px;
							text-align: left;
							font-size: 14px;
							font-weight: 700;
						}
						.ctx-item-headdesc {
							font-size: 12px;
							text-align: left;
						}
					}
					.ctx-item-b {
						flex: 1;
						text-align:left;
					}
				}
			}
			.btn-foot {
				margin-top: 10px;
				.btn {
					width: 100px;
					margin: 0 auto;
					background-color: #c88854;
					color: #fff;
					padding: 10px 20px;
					border-radius: 30px;
					cursor: pointer;
				}
			}
		}
	}
</style>
